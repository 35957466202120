import React from 'react'

const CourseHandicap = () => {
    return (
        <div >
            <h1>Course Handicap</h1>
        </div>
    )
}

export default CourseHandicap