import React from 'react'

const CourseInformation = () => {
    return (
        <div className='rates_container'>
            <div className='rates_heading'>
                COURSE INFORMATION
            </div>

            <div className='rates_container_inner'>

                <div className='event_page_description'>
                    <p>Bushwood Golf Club </p>
                    <p>+66 (0) 123-456-7890</p>
                    <p>Link to LINE</p>
                    <p>Email:</p>
                    <p> info@bushwoodgolfclub.com </p>
                    <p>Operating Hours:</p>
                    <p>6:00 AM - 6:00 PM</p>
                </div>

                <div className='event_page_description'>
                    <p>Address: </p>
                    <p>123 Moo 5 Sukhumvit Road,</p>
                    <p>Tambon Bangsaen, Amphoe</p>
                    <p>Mueang, Chonburi 20130,</p>
                    <p> info@bushwoodgolfclub.com </p>
                    <p>Thailand.</p>
                </div>

                <div className='event_page_description'>
                    <p>Get Directions: GOOGLE MAPS </p>
                </div>

                <div className='event_page_description'>
                    <p>Course Layout and Tee Information </p>
                </div>


                <div className='event_page_description'>
                    <p>* Black Tee *</p>
                    <p>7,200 yards</p>
                    <p>Par: 72</p>
                    <p>Course Rating: 4.5</p>
                </div>

                <div className='event_page_description'>
                    <p>* White Tee *</p>
                    <p>7,000 yards</p>
                    <p>Par: 70</p>
                    <p>Course Rating: 4.7</p>
                </div>

                <div className='event_page_description'>
                    <p>* Green Tee *</p>
                    <p>6,800 yards</p>
                    <p>Par: 68</p>
                    <p>Course Rating: 4.3</p>
                </div>

                <div className='event_page_description'>
                    <p>* Blue Tee *</p>
                    <p>6,400 yards</p>
                    <p>Par: 64</p>
                    <p>Course Rating: 5.0</p>
                </div>
            </div>

            <div className='rates_footer_div'>
                <button className='rates_footer_button'>Book Tea Time</button>
            </div>
        </div>
    )
}

export default CourseInformation