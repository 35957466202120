import { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import LanguagePage from './Pages/LanguagePage';
import HomePage from './Pages/HomePage';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { AiOutlineMenu } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import UpcomingEvents from './Pages/UpcomingEvents';
import EventPage from './Pages/EventPage';
import EventDetails from './Pages/EventDetails';
import Rates from './Pages/Rates';
import PromotionMain from './Pages/PromotionMain';
import PromotionDetails from './Pages/PromotionDetails';
import BookTeeTime from './Pages/BookTeeTime';
import CourseInformation from './Pages/CourseInformation';
import CourseHandicap from './Pages/CourseHandicap';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import BookingDetails from './Pages/BookingDetails';
import TimeSheet from './Pages/TimeSheet';

function App() {
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const clearLanguage = () => {
    localStorage.removeItem("language");
    window.location.reload();
  }

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="App">
      {!language ? <LanguagePage /> : (
        <>
          {isMenuOpen ? (
            <div className='layout_menu_wrapper'>
              <div className='header_container'>
                <div className='header_container_inner'>
                  <div className='img_container'>
                    <img src="Bushwood.png" alt="Bushwood Photo" className='bushwood_img_header' />
                  </div>
                  <div className='humburger_container'>
                    <RiMenu3Line onClick={handleMenuToggle} />
                  </div>
                </div>
              </div>

              <div className='menu_item_container'>
                <ul className='menu_item_list'>
                  <li onClick={handleMenuToggle} onClickCapture={() => navigate('/')}>Back to Dashboard</li>
                  <li onClick={handleMenuToggle} onClickCapture={clearLanguage}>Change Language</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => window.open('https://maps.app.goo.gl/AHnYWidwEKnU2m9K8', '_blank')}>Get Directions</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => navigate('/course_information')}>Course Information</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => navigate('/course_handicap')}>Course Handicap</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => window.open('https://www.thaicountryclub.com/', '_blank')}>Course Website</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => navigate('/privacy_policy')}>Privacy Policy</li>
                  <li onClick={handleMenuToggle} onClickCapture={() => navigate('/terms_of_service')}>Terms of Service</li>
                </ul>
              </div>

              <div className='language_footer_div'>
                <a href="https://www.lotusagency.asia/" className='language_footer_link'>Powered by Tee Time Pro</a>
              </div>
            </div>
          ) : (
            <div className='layout_wrapper'>
              <>
                <div className='header_container'>
                  <div className='header_container_inner'>
                    <div className='img_container'>
                      <img src="Bushwood.png" alt="Bushwood Photo" className='bushwood_img_header' />
                    </div>

                    <div className='humburger_container'>
                      <AiOutlineMenu onClick={handleMenuToggle} />
                    </div>
                  </div>

                  <div className='back_button_container'>
                    <FaArrowLeftLong onClick={handleBack} /> &nbsp; &nbsp;
                    <p className='back_button_text' onClick={handleBack}>Back</p>
                  </div>
                </div>
              </>
              {/* Routing starts here */}
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/upcoming_events" element={<UpcomingEvents />} />
                <Route path="/event_page" element={<EventPage />} />
                <Route path="/event_details" element={<EventDetails />} />
                <Route path='/view_rates' element={<Rates />} />
                <Route path='/promotions' element={<PromotionMain />} />
                <Route path='/promotion_details' element={<PromotionDetails />} />
                <Route path='/book_tee_time' element={<BookTeeTime />} />
                <Route path='/course_information' element={<CourseInformation />} />
                <Route path='/course_handicap' element={<CourseHandicap />} />
                <Route path='/privacy_policy' element={<PrivacyPolicy />} />
                <Route path='/terms_of_service' element={<TermsOfService />} />
                <Route path='/booking_details' element={<BookingDetails />} />
                <Route path='/timesheet' element={<TimeSheet />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
