import React from 'react'
import '../Pages/LanguagePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();

  return (
    <div className='home_container'>
       <div className='img_container_home'>
                <img src="Bushwood.png" alt="Bushwood Photo" className='bushwood_img' />
            </div>
            <div className='choose_language_container'>
                <div className='home_page_menu_box' onClick={()=>navigate('/booking_details')}>
                   Book Tee Time
                </div>
                <div className='home_page_menu_box' onClick={()=>navigate('/upcoming_events')}>
                    Events
                </div>
                <div className='home_page_menu_box' onClick={()=>navigate('/view_rates')}>
                    View Rates
                </div>
                <div className='home_page_menu_box' onClick={()=>navigate('/promotions')}>
                    Promotions
                </div>
            </div>
    </div>
  )
}

export default HomePage
