import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the default styles for the calendar
import './BookTeeTime.css'; // Your existing CSS file
import { useNavigate } from 'react-router-dom';

const BookTeeTime = () => {
    const navigate = useNavigate();

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const generateDates = () => {
        const dates = [];
        const today = new Date();

        for (let i = 0; i < 10; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            dates.push(date);
        }

        return dates;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };

    const dates = generateDates();

    return (
        <div className='book_tee_time_container'>
            <div className='book_tee_time_heading'>
                BOOK A TEE TIME
            </div>

            <div className='book_tee_time_subheading'>
                On what date would you like to play?
            </div>

            <div className='dates_container'>
                {!showCalendar ? (
                    <>
                        {dates.map((date, index) => (
                            <button key={index} className="date_button" onClick={()=>navigate('/booking_details')}>
                                {index === 0
                                    ? 'TODAY'
                                    : index === 1
                                        ? 'TOMORROW'
                                        : formatDate(date)}
                            </button>
                        ))}
                    </>
                ) : (
                    <div className="calendar_container">
                        <Calendar
                            onChange={setSelectedDate}
                            value={selectedDate}
                            onClickDay={(date) =>
                                navigate('/booking_details')
                            }
                        />
                    </div>
                )}
            </div>

            <div
                className='more_dates_text'
                onClick={() => setShowCalendar(!showCalendar)}
            >
                {showCalendar ? 'Back to Dates...' : 'More Dates....'}
            </div>
        </div>
    );
};

export default BookTeeTime;
