import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='upcoming_events_container'>
            <div className='upcoming_events_header'>
                <p>PRIVACY POLICY</p>
            </div>
            <div className='terms_of_service_content'>
                <p>
                    Lorem ipsum dolor sit amet. Sed aliquam iusto qui excepturi vero in dolores impedit et magni labore sed totam eaque et velit consequuntur. Et dignissimos eius a quae quibusdam ut modi error non quas aliquid. Et iure autem et impedit maiores qui numquam rerum At eveniet sequi. Et rerum vero et sint tenetur ut laudantium laboriosam ad sapiente quas. Ea exercitationem rerum quo perspiciatis distinctio ut sint delectus et quidem accusamus id pariatur iusto. Qui provident tenetur in eligendi officiis qui nostrum ratione ex nesciunt velit ut quae ipsam. Et omnis expedita eum earum eveniet est aliquid aspernatur est animi fugiat? Ut corrupti fuga sed iusto consequatur sit voluptas voluptatem id corrupti maxime ut impedit vero. Qui veniam enim in nobis tempore qui voluptatem temporibus ut quia atque in placeat molestiae!
                </p>
                <p>
                    In rerum animi ut assumenda dolorem et itaque ipsam. Et velit inventore rem omnis rerum et ipsam culpa ex dolore doloremque! Et dolorem libero ab ducimus recusandae qui placeat eius qui dolore omnis ut ipsa illum. Vel ratione dolorem in distinctio veritatis est aliquam delectus sed consectetur nihil. Et molestias reiciendis aut quisquam similique et voluptas libero qui dolorum dolores qui quaerat velit est voluptates dolorum. Ut velit dolores ex autem deleniti ut autem consequatur id aliquam quia id iure quam ut recusandae quis cum vero aspernatur. Qui voluptas sint id aliquam velit qui reprehenderit sint.
                </p>
                <p>
                    Et esse dolore qui quibusdam veritatis et eaque voluptas id officia ducimus et excepturi sunt. Sed quis sunt qui facilis porro ea animi quis. Ab excepturi voluptates ut voluptatem esse non deleniti animi et nulla iusto sed quod ipsum est asperiores repudiandae. In ipsam doloremque in velit repellendus cum nemo totam qui repellendus nesciunt? Aut autem galisum sed repellat sint est enim rerum nam sint assumenda hic autem numquam eum dolor commodi ut autem nemo.
                </p>
                <p>
                    Et fuga explicabo ut sunt recusandae eos voluptates reiciendis quo quod fugit sed accusantium possimus eos provident omnis qui illum obcaecati. Aut quia eveniet ut autem molestiae rem corrupti tenetur est omnis quisquam et debitis veniam ut cumque pariatur et magni eius! Et internos numquam in dicta explicabo eum voluptatem molestiae hic nobis nemo sit amet omnis sit mollitia quia. Aut odit velit in nemo dolore sed iusto officia aut assumenda autem non magni cupiditate et consectetur excepturi eos eveniet odio! In facilis quia non quia molestiae est internos voluptatibus. Non corporis omnis ad internos quidem sed iste sunt quo numquam itaque? Id quia enim non voluptatem deserunt id Quis rerum eum dolorem natus ut repudiandae consequatur sed necessitatibus nisi qui repudiandae illum. Qui autem blanditiis ea galisum commodi sit necessitatibus inventore sit saepe ratione et iste voluptates sed voluptatem ipsam non voluptates quia. Non excepturi accusantium eum blanditiis tempore et ipsum omnis.
                </p>
            </div>
        </div>
  )
}

export default PrivacyPolicy