import React from 'react';
import './PromotionMain.css';
import { useNavigate } from 'react-router-dom';

const PromotionMain = () => {
  const navigate = useNavigate();

  return (
    <div className='promotion_container'>
        <div className='promotion_heading'>
            THIS MONTHS PROMOTIONS
        </div>

        <div className='promotion_container_inner'>
          <div className='promotion_box' onClick={()=>navigate('/promotion_details')}>
            Item One
          </div>
          <div className='promotion_box' onClick={()=>navigate('/promotion_details')}>
            Item Two
          </div>
          <div className='promotion_box' onClick={()=>navigate('/promotion_details')}>
            Item Three
          </div>
        </div>

        <div className='promotion_footer_div'>
          <button className='promotion_footer_button' onClick={()=>navigate('/booking_details')}>Book Tee Time</button>
        </div>
    </div>
  )
}

export default PromotionMain
