import React from 'react'
import { useNavigate } from 'react-router-dom';


const EventPage = () => {
  const navigate = useNavigate();

  return (
    <div className='event_page_container'>
      <div className='event_page_title'>
        <p>Marriott Charity Golf Day</p>
        <p>Friday December 1, 2024</p>
      </div>
      <div className='event_page_description'>
        <p> 4 Ball Scramble </p>
        <p>Shotgun Start - 12.00</p>
        <p>10,500 baht per team</p>
        <p>Teams of 4</p>
        <p> 23/140 places left </p>

      </div>
      <div className='event_page_buttons'>
                <div className='event_page_button' >
                Book Now
                </div>
                <div className='event_page_button' onClick={()=>navigate('/event_details')}>
                See All Details
                </div>
                <div className='event_page_button' >
                Send to a Friend
                </div>
            </div>
    </div>
  )
}

export default EventPage
