/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Link } from 'react-router-dom';
import './LanguagePage.css'

const LanguagePage = () => {

    const handleLanguageSelection = (language) => {
        localStorage.setItem("language", language);
        window.location.reload();
    }

    return (
        <div className='language_container'>
            <div className='img_container'>
                <img src="Bushwood.png" alt="Bushwood Photo" className='bushwood_img' />
            </div>

            <div className='description_container'>
                Bushwood Golf Club <br />Online Booking
            </div>

            <div className='choose_language_container'>
                <div className='language_box' onClick={()=>handleLanguageSelection('th')}>
                    ภาษาไทย
                </div>
                <div className='language_box' onClick={()=>handleLanguageSelection('en')}>
                    English
                </div>
                <div className='language_box' onClick={()=>handleLanguageSelection('ko')}>
                    한국어
                </div>
            </div>

            <div className='language_footer_div'>
                <a href="https://www.lotusagency.asia/" className='language_footer_link'>Powered by Tee Time Pro</a>
            </div>
        </div>
    )
}

export default LanguagePage
