import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Pages/Rates.css';

const Rates = () => {
    const navigate = useNavigate();
    
  return (
    <div className='rates_container'>
      <div className='rates_heading'>
        RATES
      </div>

      <div className='rates_container_inner'>
        <div className='rates_box'>
            <div className='rates_box_inner'>
                Green Free (Monday to Friday)
            </div>
            <div className='rates_box_inner'>
                18 Holes: 2,000 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 1,200 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Green Free (Weekends and Holidays)
            </div>
            <div className='rates_box_inner'>
                18 Holes: 2,800 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 1,700 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Cady Fee 400 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Golf Cart Rental
            </div>
            <div className='rates_box_inner'>
                8 Holes: 700 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 400 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Club Rental
            </div>
            <div className='rates_box_inner'>
                18 Holes: 800 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 500 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Green Free (Monday to Friday)
            </div>
            <div className='rates_box_inner'>
                18 Holes: 2,000 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 1,200 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Green Free (Weekends and Holidays)
            </div>
            <div className='rates_box_inner'>
                18 Holes: 2,800 THB
            </div>
            <div className='rates_box_inner'>
                9 Holes: 1,700 THB
            </div>
        </div>

        <div className='rates_box'>
            <div className='rates_box_inner'>
                Cady Fee 400 THB
            </div>
        </div>
      </div>

      <div className='rates_footer_div'>
        <button className='rates_footer_button' onClick={()=>navigate('/booking_details')}>Book Tea Time</button>
      </div>
    </div>
  )
}

export default Rates
