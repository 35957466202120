export const API_URL = "https://bushwoodapi.ajatus.in";
// export const API_URL = "https://3e3d-2401-b200-2034-93c-84d4-7ca9-6552-93d6.ngrok-free.app";
export const API_KEY = "6LcwFZEqAAAAAApevJ_iNy08ymcxhpuiTAwmMlAr";
export const timeData = [
    {
        id: 1,
        time: "9:00 AM",
        clickable: false
    },
    {
        id: 2,
        time: "09:08 AM",
        clickable: true,
        price: "20.36"
    },
    {
        id: 3,
        time: "09:16 AM",
        clickable: true,
        price: "20.84"
    },
    {
        id: 4,
        time: "09:24 AM",
        clickable: true,
        price: "21.32"
    },
    {
        id: 5,
        time: "09:30 AM",
        clickable: false
    },
    {
        id: 6,
        time: "09:32 AM",
        clickable: true,
        price: "21.80"
    },
    {
        id: 7,
        time: "09:40 AM",
        clickable: true,
        price: "22.28"
    },
    {
        id: 8,
        time: "09:48 AM",
        clickable: true,
        price: "22.76"
    },
    {
        id: 9,
        time: "09:56 AM",
        clickable: true,
        price: "23.24"
    },
    {
        id: 10,
        time: "10:00 AM",
        clickable: false
    },
    {
        id: 11,
        time: "10:04 AM",
        clickable: true,
        price: "23.72"
    },
    {
        id: 12,
        time: "10:12 AM",
        clickable: true,
        price: "24.20"
    },
    {
        id: 13,
        time: "10:20 AM",
        clickable: true,
        price: "24.68"
    },
    {
        id: 14,
        time: "10:28 AM",
        clickable: true,
        price: "25.16"
    },
    {
        id: 15,
        time: "10:30 AM",
        clickable: false
    },
    {
        id: 16,
        time: "10:36 AM",
        clickable: true,
        price: "25.64"
    }
];