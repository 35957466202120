import React, { useEffect, useState } from 'react';
import './BookingDetails.css';
import axios from 'axios';
import { FaCalendarDay } from "react-icons/fa";
import { MdGolfCourse } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { RiCircleFill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MdArrowDropDown } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { API_KEY, API_URL, timeData } from '../constant';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const BookingDetails = () => {
    const navigate = useNavigate();

    const [showDates, setShowDates] = useState(true);
    const [showHoles, setShowHoles] = useState(false);
    const [showPlayers, setShowPlayers] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [time, setTime] = useState(null);
    const [price, setPrice] = useState(null);
    const [allHoles, setAllHoles] = useState([]);
    const [selectedHole, setSelectedHole] = useState(18);
    const [hole, setHole] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState(0);
    const [players, setPlayers] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [openDropdowns, setOpenDropdowns] = useState([]);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [offDates, setOffDates] = useState([]);
    const [offDays, setOffDays] = useState([]);

    const toggleDropdown = (index) => {
        setOpenDropdowns((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const generateDates = (offDates, offDays, targetCount = 10) => {
        const dates = [];
        const today = new Date();
        let dayOffset = 0; // Tracks the current day offset

        // Get day names
        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

        while (dates.length < targetCount) {
            const date = new Date(today);
            date.setDate(today.getDate() + dayOffset);

            // Adjust for local time zone
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            const formattedDate = localDate.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"

            // Check if the date is valid
            const dayName = dayNames[localDate.getDay()];
            if (!offDates.includes(formattedDate) && !offDays.includes(dayName)) {
                dates.push(localDate);
            }

            dayOffset++; // Move to the next day
        }

        return dates;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
    };

    const availableDates = generateDates(offDates, offDays);

    const fethOffDates = async () => {
        const response = await axios.get(API_URL + "/api/getAllDateOffs");
        setOffDates(response.data); // Example: ["2024-12-09","2024-12-11"]
    }

    const fetchOffDays = async () => {
        const response = await axios.get(API_URL + "/api/getAllDayOffs");
        setOffDays(response.data); // Example: ["SUN","SAT"]
    }

    const fetchAllHoles = async () => {
        const response = await axios.get(API_URL + "/api/getAllCourseHoleMappings");
        if (response.status === 200) {
            setAllHoles(response.data);
            setSelectedHole(response.data[response.data.length - 1]);
        }
    }

    useEffect(() => {
        fethOffDates();
        fetchOffDays();
        fetchAllHoles();
    }, []);

    const isDisabledDate = (date) => {
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = localDate.toISOString().split("T")[0];

        if (offDates.includes(formattedDate)) {
            console.log("formattedDate:", formattedDate);
            return true;
        }

        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const dayName = dayNames[date.getDay()];
        if (offDays.includes(dayName)) {
            return true;
        }

        return false;
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        handleShowDates();
        setShowHoles(true);
    };

    const handleHoleSelect = () => {
        setShowHoles(!showHoles);
        setHole(selectedHole);
        setShowPlayers(true);
    };

    const handleSelectPlayers = () => {
        setShowPlayers(!showPlayers);
        setPlayers(selectedPlayers);
        handleShowPlayers();
        setShowTime(true);
    }

    const handleTimeClick = (time) => {
        setTime(time.time);
        setPrice(time.price);
        setShowTime(false);
        setShowUser(true);
    };

    const handleUserDetailsSubmit = () => {
        setShowUser(!showUser);
        handleShowUser();
        handleSubmit();
    };

    const handleShowDates = () => {
        if (showDates === true) {
            setShowDates(false);
        } else {
            setShowDates(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowTime(false);
            setShowUser(false);
        }
    }

    const handleShowHoles = () => {
        if (showHoles === true) {
            setShowHoles(false);
        } else {
            setShowDates(false);
            setShowHoles(true);
            setShowPlayers(false);
            setShowTime(false);
            setShowUser(false);
        }
    }

    const handleShowPlayers = () => {
        if (showPlayers === true) {
            setShowPlayers(false);
        } else {
            setShowDates(false);
            setShowPlayers(true);
            setShowHoles(false);
            setShowTime(false);
            setShowUser(false);
        }
    }

    const handleShowTime = () => {
        if (showTime === true) {
            setShowTime(false);
        } else {
            setShowDates(false);
            setShowTime(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowUser(false);
        }
    }

    const handleShowUser = () => {
        if (showUser === true) {
            setShowUser(false);
        } else {
            setShowDates(false);
            setShowUser(true);
            setShowHoles(false);
            setShowPlayers(false);
            setShowTime(false);
        }
    }

    const formatDateForBody = (dateString) => {
        if (dateString !== null) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}.${month}.${day}`;
        } else {
            return null;
        }
    }

    const convertTo24HourFormat = (timeString) => {
        if (timeString !== null) {
            const [time, modifier] = timeString.split(' ');
            let [hours, minutes] = time.split(':');

            if (modifier === 'PM' && hours !== '12') {
                hours = String(Number(hours) + 12);
            } else if (modifier === 'AM' && hours === '12') {
                hours = '00';
            }

            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        } else {
            return null;
        }
    }

    const createBody = () => {
        if (name.trim() === '', email.trim() === '', phoneNumber.trim() === '') {
            setShowUser(true);
            return {
                error: 'Please enter correct user details'
            }
        }

        if (phoneNumber.length !== 10) {
            setShowUser(true);
            return {
                error: 'Please enter a valid phone number'
            }
        }

        if (!/^\d{10}$/.test(phoneNumber)) {
            setShowUser(true);
            return {
                error: 'Please enter a valid phone number'
            }
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setShowUser(true);
            return {
                error: 'Please enter a valid email'
            }
        }

        if (time === null) {
            setShowTime(true);
            return {
                error: 'Please select a time'
            }
        }

        if (hole === null) {
            setShowHoles(true);
            return {
                error: 'Please select a hole type'
            }
        }

        if (players === null) {
            setShowPlayers(true);
            return {
                error: 'Please select number of players'
            }
        }

        if (selectedDate === null) {
            setShowDates(true);
            return {
                error: 'Please select a date'
            }
        }

        return {
            "TeeTime": convertTo24HourFormat(time),
            "Date": formatDateForBody(selectedDate),
            "TotalGolfers": players,
            "HoleType": hole.HoleName,
            "BookingPricing": hole.BasePrice,
            "Name": name,
            "Email": email,
            "Mobile": phoneNumber
        }
    }

    const handleSubmit = async () => {
        const body = createBody();

        if (body.error) {
            alert(body.error);
            return;
        }

        setShowCaptcha(true);
        return;
    }

    const onCaptchaChange = async (value) => {
        if (value) {
            const response = await axios.post(API_URL + "/api/verify-recaptcha", {
                token: value
            });

            if (response.status === 200) {
                const body = createBody();

                const response = await axios.post(API_URL + "/api/createNewBooking", body);

                if (response.status === 201) {
                    alert("Booking created successfully");
                    navigate("/");
                }

                if (response.status !== 201) {
                    alert("Error creating booking");
                }
            }
        }
    }

    return (
        <div className='booking_details_container'>
            <div className='booking_details_heading'>
                BOOKING DETAILS
            </div>

            {/* <div className='booking_details_subheading'>
                Green Aerification will start on 3 November
            </div> */}


            {showCaptcha ? (
                <div className='recaptcha_div'>
                    <button className='edit_booking_button' onClick={() => setShowCaptcha(false)}>Edit Booking Details</button>
                    <p className='recaptcha_text'>Please verify that you are not a robot</p>
                    <ReCAPTCHA
                        sitekey={API_KEY}
                        onChange={onCaptchaChange}
                    />
                </div>
            ) : (
                <div className='booking_details_full_container'>
                    <div className='date_selection_div'>
                        <div className='booking_details_section_upper' onClick={handleShowDates}>
                            <div className='booking_details_section1'>
                                <div className='booking_details_section1_left'>
                                    <div className='booking_details_section1_left1'>
                                        <FaCalendarDay />
                                    </div>
                                    {showDates ? (
                                        <div className='booking_details_section1_left2'>
                                            Select Date
                                        </div>
                                    ) : (
                                        <div className='booking_details_section1_left2'>
                                            {selectedDate && formatDate(selectedDate) || 'Select Date'}
                                        </div>
                                    )}
                                </div>

                                {showDates ? (
                                    <div className='booking_details_section1_right'>
                                        <MdArrowDropDown />
                                    </div>
                                ) : (
                                    <div className='booking_details_section1_right'>
                                        Edit
                                    </div>
                                )}
                            </div>
                        </div>

                        {showDates && (
                            <div className='dates_container2'>
                                {!showCalendar ? (
                                    <>
                                        {availableDates.map((date, index) => {
                                            const today = new Date();
                                            const tomorrow = new Date(today);
                                            tomorrow.setDate(today.getDate() + 1);

                                            // Check if the date is "TODAY" or "TOMORROW"
                                            const isToday = formatDate(date) === formatDate(today);
                                            const isTomorrow = formatDate(date) === formatDate(tomorrow);

                                            return (
                                                <button key={index} className="date_button2" onClick={() => handleDateSelect(date)}>
                                                    {isToday
                                                        ? "TODAY"
                                                        : isTomorrow
                                                            ? "TOMORROW"
                                                            : formatDate(date)}
                                                </button>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <div className="calendar_container2" onClick={() => setShowDates(false)}>
                                        <Calendar
                                            onChange={setSelectedDate}
                                            value={selectedDate}
                                            tileDisabled={({ date }) => isDisabledDate(date)}
                                        />
                                    </div>
                                )}
                                <div className='more_dates_text2' onClick={() => setShowCalendar(!showCalendar)}>
                                    {showCalendar ? 'Back to Dates...' : 'More Dates....'}
                                </div>
                            </div>
                        )}

                    </div>

                    <div className='booking_details_section_upper' onClick={handleShowHoles}>
                        <div className='booking_details_section1'>
                            <div className='booking_details_section1_left'>
                                <div className='booking_details_section1_left1'>
                                    <MdGolfCourse />
                                </div>
                                <div className='booking_details_section1_left2'>
                                    {hole === null ? 'Select Hole' : hole.HoleName + " Holes"}
                                </div>
                            </div>

                            {showHoles ? (
                                <div className='booking_details_section1_right'>
                                    <MdArrowDropDown />
                                </div>
                            ) : (
                                <div className='booking_details_section1_right'>
                                    Edit
                                </div>
                            )}
                        </div>

                    </div>
                    {showHoles && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_details_section2'>
                                <div className='booking_details_section1_heading'>
                                    How many holes do you want to play?
                                </div>
                                <div className='booking_details_section1_subheading'>
                                    <div className='booking_details_section1_heading_line'></div>
                                </div>
                            </div>
                            <div className='booking_details_section2'>
                                <div className='hole_buttons_container'>
                                    {allHoles.map((hole, index) => (
                                        <div key={index} className={`${selectedHole.HoleName === hole.HoleName ? 'hole_button_active' : 'hole_button'}`} onClick={() => setSelectedHole(hole)}>
                                            {hole.HoleName} Holes
                                        </div>
                                    ))}
                                </div>
                                <div className='hole_buttons_container2'>
                                    <div className='hole_button_submit' onClick={handleHoleSelect}>
                                        Continue
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='booking_details_section_upper' onClick={handleShowPlayers}>
                        <div className='booking_details_section1'>
                            <div className='booking_details_section1_left'>
                                <div className='booking_details_section1_left1'>
                                    <HiUsers />
                                </div>
                                {players === 0 ? (
                                    <div className='booking_details_section1_left2'>
                                        Select Players
                                    </div>) : (
                                    <div className='booking_details_section1_left2'>
                                        {players} player{players === 1 ? '' : 's'}
                                    </div>)}
                                <div className='booking_details_section1_left3'>
                                    {Array.from({ length: players }, (_, index) => (
                                        <RiCircleFill />
                                    ))}
                                </div>
                            </div>
                            {showPlayers ? (
                                <div className='booking_details_section1_right'>
                                    <MdArrowDropDown />
                                </div>
                            ) : (
                                <div className='booking_details_section1_right'>
                                    Edit
                                </div>
                            )}
                        </div>
                    </div>

                    {showPlayers && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_details_section2'>
                                <div className='booking_details_section1_heading'>
                                    How many golfers are in your party?
                                </div>
                                <div className='booking_details_section1_subheading'>
                                    <div className='booking_details_section1_heading_line'></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showPlayers && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_details_section3'>
                                <div className='booking_details_section3_inner'>
                                    <div className={selectedPlayers === 1 ? 'block_page_container21_active' : 'block_page_container21'} onClick={() => setSelectedPlayers(1)}>
                                        1
                                    </div>
                                    <div className={selectedPlayers === 2 ? 'block_page_container21_active' : 'block_page_container21'} onClick={() => setSelectedPlayers(2)}>
                                        2
                                    </div>
                                    <div className={selectedPlayers === 3 ? 'block_page_container21_active' : 'block_page_container21'} onClick={() => setSelectedPlayers(3)}>
                                        3
                                    </div>
                                    <div className={selectedPlayers === 4 ? 'block_page_container21_active' : 'block_page_container21'} onClick={() => setSelectedPlayers(4)}>
                                        4
                                    </div>
                                </div>
                                <div className='muna_outer'>
                                    <div className='muna_inner'></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showPlayers && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_details_section2'>
                                <div className='booking_details_section1_heading'>
                                    Please select your player category
                                </div>
                                <div className='booking_details_section1_subheading'>
                                    <div className='booking_details_section1_heading_line'></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showPlayers && (
                        <div className="booking_details_section_upper2">
                            <div className="booking_details_section3">
                                {Array.from({ length: selectedPlayers }, (_, index) => (
                                    <div className="booking_details_section12" key={index} onClick={() => toggleDropdown(index)}>
                                        <div className='players_details_container'>
                                            <div
                                                className="booking_details_section1_left"
                                            >
                                                <div className="booking_details_section1_left12">
                                                    <RiCircleFill />
                                                </div>
                                                <div className="booking_details_section1_left2">Public</div>
                                            </div>

                                            <div className="booking_details_section1_right2">
                                                <MdKeyboardArrowDown />
                                            </div>
                                        </div>

                                        {openDropdowns[index] && (
                                            <div className="dropdown_menu">
                                                <div className="dropdown_item">Option 1</div>
                                                <div className="dropdown_item">Option 2</div>
                                                <div className="dropdown_item">Option 3</div>
                                            </div>
                                        )}
                                    </div>
                                ))}

                                <div className='hole_button_submit2' onClick={handleSelectPlayers}>
                                    Continue
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='booking_details_section_upper' onClick={handleShowTime}>
                        <div className='booking_details_section1'>
                            <div className='booking_details_section1_left'>
                                <div className='booking_details_section1_left1'>
                                    <FaClock />
                                </div>
                                <div className='booking_details_section1_left2'>
                                    {time === null ? 'Select Time' : time}
                                </div>
                            </div>

                            {showTime ? (
                                <div className='booking_details_section1_right'>
                                    <MdArrowDropDown />
                                </div>
                            ) : (
                                <div className='booking_details_section1_right'>
                                    Edit
                                </div>
                            )}
                        </div>
                    </div>

                    {showTime && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_time_section'>
                                <div className='booking_details_section1_heading'>
                                    <div className='booking_details_section1_heading'>
                                        What time would you like to play?
                                    </div>
                                    <div className='booking_details_section1_subheading'>
                                        <div className='booking_details_section1_heading_line'></div>
                                    </div>
                                </div>

                                {hole === null ? (
                                    <>
                                        <div className='booking_time_div' style={{marginBottom: '20px', fontSize: '13px'}}>
                                            Please select hole type first
                                        </div>
                                    </>
                                ) : (
                                    <div className='booking_time_div'>
                                    {timeData.map((item) => (
                                        <div className='time_div_inner' key={item.id}>
                                            {item.clickable ? (
                                                <>
                                                    <div className={item.time === time ? 'time_div_inner_left2_active' : 'time_div_inner_left2'}>
                                                        {item.time}
                                                    </div>
                                                    <div className='time_div_inner_right2' onClick={() => handleTimeClick(item)}>
                                                        <div className='time_div_inner_right2_inner'>
                                                            &#3647;{hole.BasePrice}
                                                        </div>
                                                        <div className='time_div_inner_right2_inner2'>
                                                            <MdOutlineKeyboardArrowRight />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='time_div_inner_left'>
                                                        {item.time}
                                                    </div>
                                                    <div className='time_div_inner_right'>
                                                        <hr />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className='booking_details_section_upper' onClick={handleShowUser}>
                        <div className='booking_details_section1'>
                            <div className='booking_details_section1_left'>
                                <div className='booking_details_section1_left1'>
                                    <FaUser />
                                </div>
                                <div className='booking_details_section1_left2'>
                                    {user === null ? 'User Details' : user.name}
                                </div>
                            </div>

                            {showUser ? (
                                <div className='booking_details_section1_right'>
                                    <MdArrowDropDown />
                                </div>
                            ) : (
                                <div className='booking_details_section1_right'>
                                    Edit
                                </div>
                            )}
                        </div>
                    </div>

                    {showUser && (
                        <div className='booking_details_section_upper2'>
                            <div className='booking_time_section'>
                                <div className='booking_details_section1_heading'>
                                    <div className='booking_details_section1_heading'>
                                        Please enter your details
                                    </div>
                                    <div className='booking_details_section1_subheading'>
                                        <div className='booking_details_section1_heading_line'></div>
                                    </div>
                                </div>

                                <div className='user_details_div'>
                                    <div className='user_details_div_inner'>
                                        <input className='user_input' onChange={(e) => setName(e.target.value)} name='name' value={name} type='text' placeholder='Name' />
                                        <input className='user_input' onChange={(e) => setEmail(e.target.value)} name='email' value={email} type='email' placeholder='Email' />
                                        <input className='user_input' onChange={(e) => setPhoneNumber(e.target.value)} name='phone' value={phoneNumber} type='text' placeholder='Phone' />
                                        <div className='hole_button_submit' onClick={handleUserDetailsSubmit}>
                                            Submit
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}

                </div>
            )}
        </div>
    )
}

export default BookingDetails
