import React from 'react'
import { useNavigate } from 'react-router-dom';


const UpcomingEvents = () => {
  const navigate = useNavigate();
  return (
    <div className='upcoming_events_container'>

      <div className='upcoming_events_header'>
        <p>UPCOMING EVENTS</p>
      </div>
      <div className='upcoming_events_content'>
        <ul className='upcoming_events_list'>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          <li>
            <div className='upcoming_events_card'>
              <div className='upcoming_events_card_body'>
                <div className='upcoming_events_card_left_content'>
                  <div className='upcoming_events_card_left_content_month'>
                  <p>DEC</p>
                  </div>
                  <div className='upcoming_events_card_left_content_date'>
                  <p>01</p>
                  </div>

                </div>
                <div className='upcoming_events_card_right_content'>
                  <p>Marriott Charity Golf Day</p>
                  <p>Friday December 1, 2024</p>
                  <p>23/140 places left</p>
                </div>
              </div>
              <div className='upcoming_events_card_footer' onClick={()=>navigate('/event_page')}>
                <p>View Event</p>
              </div>
            </div>
            <hr/>
          </li>
          
        </ul>
      </div>

    </div>
  )
}

export default UpcomingEvents
