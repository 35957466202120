import React from 'react';
import { useNavigate } from 'react-router-dom';

const PromotionDetails = () => {
    const navigate = useNavigate();

    return (
        <div className='promotion_details_container'>
            <div className='promotion_details_heading'>
                Promotion Name
            </div>

            <div className='promotion_details_heading2'>
                <button className='promotion_details_button' onClick={()=>navigate('/booking_details')}>Book Tee Time</button>
            </div>

            <div className='promotion_details_container_inner'>
                <div className='promotion_image_div'>
                    <img src="https://destinationgolf.travel/wp-content/uploads/2022/06/Marbella-Golf-Club-summer-2022-offer-696x696.jpg" alt="Promotion Image" className='promotion_image' />
                </div>

                <div className='promotion_details_description'>
                    <p>This summer, enjoy exclusive golf offers from May 23 to September 11, 2022, with various options tailored to enhance your golfing experience. Whether you're an individual player, a couple, or part of a group, these deals provide flexibility, affordability, and added perks, ensuring you make the most of your time on the green.</p>
                    <p>For small groups, the 2 Players Green Fee is an excellent choice, priced at €159. This offer includes a shared buggy for easy mobility and a €10 voucher to spend at the bar or restaurant, making it perfect for a day out with a friend or loved one. For solo players, the Twilight 1 Player Green Fee is available for just €69, also with a shared buggy. Twilight hours are ideal for those seeking cooler weather and a peaceful atmosphere on the course.</p>
                    <p>For avid golfers, the monthly vouchers offer great value and convenience. The Individual Monthly Voucher is priced at €600 and includes 20 green fees, each with a shared buggy. Couples can opt for the Couples Monthly Voucher at €1,100, providing 20 green fees per person with shared buggies. These options are designed for those planning to play frequently throughout the month, offering significant savings and flexibility.</p>
                    <p>For a truly immersive golfing experience, the Unlimited Golf package allows unlimited play during the selected period. The 1 Week Unlimited Golf package is available for €300, while the 2 Weeks Unlimited Golf package costs €450. Both options include a shared buggy, ensuring you can focus on enjoying your game without worrying about transportation across the course. These packages are perfect for holidaymakers or enthusiasts looking to maximize their time on the green.</p>
                    <p>For those seeking additional savings, the 10 Green Fees Voucher is available for €550. This voucher includes a shared buggy and is ideal for players who prefer flexible scheduling without committing to a monthly plan. Alternatively, the Twilight Option offers the same 10 green fees at a reduced price of €450, specifically for twilight hours. This option is perfect for those who enjoy playing during the cooler, quieter times of the day.</p>

                </div>
            </div>
        </div>
    )
}

export default PromotionDetails
