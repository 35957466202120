import React, { useState } from 'react';
import "./TimeSheet.css";

const TimeSheet = () => {
  const [expandedTee, setExpandedTee] = useState(null);

  const toggleTeeTime = (id) => {
    setExpandedTee(expandedTee === id ? null : id);
  };

  const slabs = [
    {
      slab: "9:00 AM - 10:00 AM",
      teeTimes: [
        {
          id: 1,
          time: "9:00 AM",
          seats: 3,
          totalSeats: 4,
          members: ["John", "Sarah", "Mike"],
        },
        {
          id: 2,
          time: "9:15 AM",
          seats: 1,
          totalSeats: 4,
          members: ["Emily"],
        },
        {
          id: 3,
          time: "9:30 AM",
          seats: 4,
          totalSeats: 4,
          members: ["Alice", "Bob", "Charlie", "Diana"],
        },
        {
          id: 4,
          time: "9:45 AM",
          seats: 2,
          totalSeats: 4,
          members: ["Jake", "Emma"],
        },
      ],
    },
    {
      slab: "10:00 AM - 11:00 AM",
      teeTimes: [
        {
          id: 5,
          time: "10:00 AM",
          seats: 2,
          totalSeats: 4,
          members: ["Olivia", "Liam"],
        },
        {
          id: 6,
          time: "10:15 AM",
          seats: 1,
          totalSeats: 4,
          members: ["Noah"],
        },
        {
          id: 7,
          time: "10:30 AM",
          seats: 3,
          totalSeats: 4,
          members: ["Emma", "Olivia", "Liam"],
        },
        {
          id: 8,
          time: "10:45 AM",
          seats: 1,
          totalSeats: 4,
          members: ["Noah"],
        },
      ],
    },
    {
      slab: "11:00 AM - 12:00 PM",
      teeTimes: [
        {
          id: 9,
          time: "11:00 AM",
          seats: 2,
          totalSeats: 4,
          members: ["Olivia", "Liam"],
        },
        {
          id: 10,
          time: "11:15 AM",
          seats: 1,
          totalSeats: 4,
          members: ["Noah"],
        },
        {
          id: 11,
          time: "11:30 AM",
          seats: 3,
          totalSeats: 4,
          members: ["Emma", "Olivia", "Liam"],
        },
        {
          id: 12,
          time: "11:45 AM",
          seats: 1,
          totalSeats: 4,
          members: ["Noah"],
        }
      ],
    },
  ];

  return (
    <div className="timesheet_container">
      <div className="timesheet_heading">Booking Timesheet</div>
      <div className="timesheet_slabs">
        {slabs.map((slab, slabIndex) => (
          <div key={slabIndex} className="timesheet_slab">
            <div className="slab_title">{slab.slab}</div>
            {slab.teeTimes.map((tee) => (
              <div key={tee.id} className="tee_time_item">
                <div className="tee_main_info">
                  <div className="tee_time">{tee.time}</div>
                  <div className="tee_seats">
                    Seats: {tee.seats}/{tee.totalSeats}
                  </div>
                  <button
                    className={`tee_button ${
                      tee.seats > tee.totalSeats / 2
                        ? "view_button"
                        : "join_button"
                    }`}
                  >
                    {tee.seats > tee.totalSeats / 2 ? "View" : "Join"}
                  </button>
                  <button
                    className="expand_button"
                    onClick={() => toggleTeeTime(tee.id)}
                  >
                    {expandedTee === tee.id ? "Collapse" : "Expand"}
                  </button>
                </div>
                {expandedTee === tee.id && (
                  <div className="tee_members">
                    <div className="members_heading">Members:</div>
                    <ul>
                      {tee.members.map((member, index) => (
                        <li key={index}>{member}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeSheet;
